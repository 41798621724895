function monetary(value) {
    if (value === "") {
        return ""
    }
    let currency = 'RSD'
    const formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
    return formated.split(currency)[1] + ' ' + currency;
}

export default {
    "SR": {
        invoices: "Fakture",
        language: "Jezik",
        flexible_benefits: "Fleksibilni benefiti",
        logout: "Odjavite se",
        fixed_benefits: "Fiksni benefiti",
        special_offers: "Specijalne ponude",
        no_benefits: "Nema benefita",
        benefit_cockpit: "Paleta benefita",
        flexible_desc: "U sekciji levo možete pronaći sve Benefite koji se mogu ostvariti uz pomoć budžeta koji je obezbedio Vaš poslodavac. Molimo Vas da odaberete benefit koji želite.",
        fix_desc: "U ovoj sekciji možete pronaći sve informacije o fiksnim (osnovnim) benefitima koje obezbeđuje Vaš poslodavac. Ovi benefiti ne mogu biti izabrani, otkazani ili promenjeni jer su oni unapred predefinisani za Vas.",
        special_desc: "",
        cart: "Korpa",
        prev_cart: "Pregledajte korpu",
        total: "Ukupno",
        empty_cart: "Korpa je prazna",
        proceed: "Kupi",
        tital_benefits: "Ukupno benefita",
        by_error: "Nemate dovoljno novca",
        profile: "Profil",
        profile_text: "Ovde mozete da pregledate i izmenite informacije o profilu",
        profile_img: "Profilna slika",
        current_balance: "Trenutno stanje",
        benefits_chosen: "Izabrano benefita",
        total_spent: "Ukupno potrošeno",
        yearly_total_budget: "Ukupan budžet",
        spent: "Potrošeno",
        left: "Preostalo",
        company: "Kompanija",
        first_name: "Ime",
        last_name: "Prezime",
        company_mail: "Kompanijski e-mail",
        phone_number: "Broj telefona",
        change_password: "Promena lozinke",
        current_password: "Trenutna lozinka",
        new_password: "Nova lozinka",
        repeat_password: "Potvrdite novu lozinku",
        error: "Greška",
        password_changed: "Lozinka je promenjena",
        transactions: "Transakcije",
        req_sent: "Slanje zahteva",
        req_process: "Obrada zahteva",
        req_completed: "Zahtev obrađen",
        success_by: "Uspešno ste izvršili kupovinu",
        complete: "Završi",
        not_completed: "Neobrađene",
        subcategories: "Potkategorije",
        name: "Naziv",
        required: "Ovo polje je obavezno",
        not_email: "Neispravan email",
        edit_provider: "Uredi provajdera",
        are_you_sure: "Da li ste  sigurni?",
        prov_will_del: "Provajder će biti obrisan",
        comp_will_del: "Kompanija će biti obrisana",
        user_will_del: "Korisnik će biti obrisan",
        admin_will_del: "Administrator će biti obrisan",
        benefit_will_del: "Benefit će biti obrisan",
        delete: "Obrisati",
        cancel: "Prekini",
        create_benefit: "Kreirajte benefit",
        create_provider: "Kreirajte provajdera",
        discount: "Popust",
        category: "Kategorija",
        subcategory: "Potkategorija",
        valid_from: "Važi od",
        valid_to: "Važi do",
        description: "Opis",
        short_description: "Kratak opis",
        company_price: "Procenat poreza i doprinosa",
        instructions: "Instrukcije",
        limitations: "Ograničenja",
        note: "Informacija o poreskom tretmanu",
        edit_benefit: "Izmeni benefit",
        details: "Detalji",
        promo: "Promo materijal",
        provider: "Provajder",
        add_cart: "Dodaj u korpu",
        remove_cart: "Ukloni iz korpe",
        most_popular: "Najpopularnije",
        recently_added: "Najnovije",
        favorites: "Omiljeno",
        no_favorites: "Niste dodali nijedan omiljeni benefit na listu",
        sort_by: "Sortiraj po:",
        bought: "Popularnosti", added: "Vreme",
        price: "Cena",
        create_company: "Kreirajte kompaniju",
        comany_id: "Kompanijski ID",
        create_user: "Kreiraj korisnika",
        base_balance: "Početno stanje",
        id_company: "Kompanijski ID",
        user_created: "Korisnik je kreiran",
        edit_user: "Izmeni korisnika",
        create_fixed_benefits: "Kreiraj fiksne benefite",
        not_num: "Unos nije broj",
        active_status: "Aktivan status",
        view_all: "Pogledaj sve",
        nfc: "NFC",
        date: "Datum",
        id_user: "Korisnički ID",
        vat: "*PDV uključen u cenu",
        checked_cart: "Razumem da će moj poslodavac platiti naknadno porez i doprinose na prikazanu cenu benefita prema državnoj legislativi",
        edit_company: "Uredi kompaniju",
        contact: "Kontakt",
        address: "Adresa",
        email: "E-mail",
        support: "Veb-sajt",
        support_msg: "Čet podrška uživo, je dostupna na web platformi",
        about: "O Nama",
        providers: "Provajderi",
        proceed_to_checkout: "Nastavite sa kupovinom",
        confirm_purchase: "Potvrdite kupovinu",
        back_to_cart: "Nazad u korpu",
        transaction_successful: "Uspešna kupovina",
        money_left: (money) => { return `Ostalo Vam je ${monetary(money)} na vašem nalogu.` },
        benefit_suggestion: "Predložite benefit",
        write_suggestion: "Napišite Vašu preporuku",
        confirm: "Potvrdi",
        success_sending_suggestion: "Uspešno ste poslali predlog",
        close: "Zatvori",
        companies: "Kompanije",
        benefit_providers: "Benefit provajderi",
        create_admin: "Kreirajte administratora",
        tutorial: "Tutorijal",
        amount: "Količina",
        user_edited: "Korisnik je izmenjen",
        sort: "Sortiraj po",
        additional_payrole: "Dodatni porez na teret poslodavca: ",
        additional_tax: "*Ovo je samo procenjena vrednost poreza i doprinosa koji će dodatno biti obračunati i plaćeni od strane Vašeg poslodavca na regularnu cenu benefita",
        search: "Pretraži",
        image: "Slika",
        actions: "Akcije",
        balance: "Ukupan budžet",
        position: "Pozicija",
        country: "Država",
        all_questions: "Sva pitanja",
        team_building: "Tim bilding",
        load: "Učitaj",
        change_str: "Izmeni",
        order_summary: "Rezime narudžbine",
        fully_taxable: "U potpunosti oporeziv benefit",
        search_by_provider_name: "Pretraži po nazivu provajdera",
        search_by_benefit_name: "Pretraži po nazivu benefita",
        add_benefit: "Dodaj benefit",
        provider_admin: "Admin provajder",
        add_admin: "Dodaj administratora",
    },
    "EN": {
        language: "Language",
        flexible_benefits: "Flexible Benefits",
        logout: "Log Out",
        current_balance: "Current Balance",
        fixed_benefits: "Fixed Benefits",
        special_offers: "Special Offers",
        no_benefits: "There are no benefits",
        benefit_cockpit: "Benefit cockpit",
        benefits_desc: "In the left menu bar section you can find all the benefits that can be accessed with the budget provided by your employer. Please choose the benefit according to your wishes.",
        flexible_desc: "In the left menu bar section you can find all the benefits that can be accessed with the budget provided by your employer. Please choose the benefit according to your wishes.",
        fix_desc: "In this section you can find all the information about the fixed benefits provided by your employer. These benefits can not be chosen, cancelled or exchanged as they are provided by default for you.",
        special_desc: "",
        cart: "Cart",
        prev_cart: "View Full Cart",
        total: "Total",
        empty_cart: "Cart is empty",
        proceed: "Proceed to checkout",
        tital_benefits: "Total benefits",
        by_error: "You don't have enough money",
        profile: "Profile",
        profile_text: "here you can see and change profile info",
        profile_img: "Profile image",
        benefits_chosen: "Benefits Chosen",
        total_spent: "Total Spent",
        yearly_total_budget: "Yearly Total Budget",
        spent: "Spent",
        left: "Left",
        company: "Company",
        first_name: "First name",
        last_name: "Last name",
        company_mail: "Company Email",
        phone_number: "Phone number",
        change_password: "Change password",
        current_password: "Current password",
        new_password: "New password",
        repeat_password: "Repeat password",
        error: "Error",
        password_changed: "Password changed",
        transactions: "Transactions",
        req_sent: "Request sent",
        req_process: "Request in process",
        req_completed: "Request completed",
        success_by: "You have successfully made a purchase",
        complete: "Complete",
        not_completed: "Not completed",
        subcategories: "Subcategories",
        name: "Name",
        required: "This field is required",
        not_email: "this is not email",
        edit_provider: "Edit provider",
        are_you_sure: "Are you sure?",
        prov_will_del: "Provider will be deleted",
        comp_will_del: "Company will be deleted",
        user_will_del: "User will be deleted",
        admin_will_del: "Admin will be deletet",
        benefit_will_del: "Benefit will be deleted",
        delete: "Delete",
        cancel: "Cancel",
        create_benefit: "Create benefit",
        create_provider: "Create provider",
        price: "Price",
        discount: "Discount",
        category: "Category",
        subcategory: "Subcategory",
        valid_from: "Valid from",
        valid_to: "Valid to",
        description: "Description",
        short_description: "Short description",
        company_price: "Percentage of tax and contribution",
        instructions: "Instructions",
        limitations: "Limitations",
        note: "Tax info",
        edit_benefit: "Edit benefit",
        details: "Details",
        promo: "Promo material",
        provider: "Provider",
        add_cart: "Add to cart",
        remove_cart: "Remove from cart",
        most_popular: "Most popular",
        recently_added: "Recently added",
        favorites: "Favorites",
        no_favorites: "You haven’t added any benefit to your favorites list",
        sort_by: "Sort by",
        bought: "Popularity", added: "Time",
        create_company: "Create company",
        comany_id: "Company ID",
        create_user: "Create user",
        base_balance: "Base balance",
        id_company: "Company ID",
        user_created: "User is created",
        edit_user: "Edit user",
        create_fixed_benefits: "Create fixed benefits",
        not_num: "Input is not a number",
        active_status: "Active status",
        view_all: "View All",
        nfc: "NFC",
        date: "Date",
        id_user: "User ID",
        vat: "*VAT included",
        checked_cart: "I understand that my employer will pay additional payroll taxes and contributions on the benefit price according to fiscal legislation",
        edit_company: "Edit company",
        contact: "Contact",
        address: "Address",
        email: "E-mail",
        support: "Website",
        support_msg: "You can find live chat support on our web platform",
        about: "About",
        providers: "Providers",
        proceed_to_checkout: "Proceed To Checkout",
        confirm_purchase: "Confirm Purchase",
        back_to_cart: "Back To Cart",
        transaction_successful: "Transaction Successful",
        money_left: (money) => { return `You have left ${monetary(money)} on your account.` },
        benefit_suggestion: "Benefit suggestion",
        write_suggestion: "Write your suggestion",
        confirm: "Confirm",
        success_sending_suggestion: "Suggestion sent",
        close: "Close",
        companies: "Companies",
        benefit_providers: "Benefit providers",
        create_admin: "Create administrator",
        tutorial: "Tutorial",
        user_edited: "User is edited",
        amount: "Amount",
        sort: "Sort",
        additional_payrole: "Additional payroll tax: ",
        additional_tax: "*This is just an estimated tax and contribution amount that will be additionally paid by your employer on the standard benefits price",
        search: "Search",
        image: "Image",
        actions: "Actions",
        balance: "Base balance",
        position: "Position",
        country: "Country",
        all_questions: "All questions",
        team_building: "Team building",
        load: "Load",
        change_str: "Change",
        order_summary: "Order summary",
        fully_taxable: "Fully taxable benefit",
        invoices: "Invoices",
        search_by_provider_name: "Search by Provider Name",
        search_by_benefit_name: "Search by Benefit Name",
        add_benefit: "Add Benefit",
        provider_admin: "Provider Admin",
        add_admin: "Add Administrator",
    }
}

<template>
  <div class="bg-gray-100 min-h-screen">
    <success-notification :show="$store.state.ui.success"/>
    <error-notification :show="$store.state.ui.error" :message="$store.state.ui.error_message"/>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
<!--                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />-->x
                </button>
              </div>
            </TransitionChild>
            <new-logo class="w-10 h-10"/>
<!--            <div class="flex-shrink-0 flex items-center px-4">-->
<!--              <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-base-600-mark-gray-800-text.svg" alt="Workflow" />-->
<!--            </div>-->
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                </a>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <sidebar @toggle="setLogo" @isShowSidebar="setLogo($event)"/>
    <div class="flex flex-col flex-1">
      <div class="bg-gray-100 sticky top-0 z-10 h-24 mb-6">
        <router-link to="/" @click="back_to_home"  class="cursor-pointer hidden lg:block">
          <img src="/logo2.png" :class="`fixed z-40 top-8 lg:top-7 w-24 h-12 xl:w-28 xl:h-14 ml-2 md:ml-3.5 lg:ml-3.5 xl:ml-5 animation duration-300 ease-in-out ${open ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`">
          <img src="/logo1.png" :class="`fixed z-40 top-8 lg:top-7 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 animation duration-300 ease-in-out ${!open ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`">
        </router-link>
        <router-link to="/" @click="back_to_home"  class="cursor-pointer lg:hidden">
          <!--          <img src="/logo1.png" :class="`fixed z-40 top-8 lg:top-7 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 animation duration-300 ease-in-out`">-->
          <img src="/white-logo-h.png" :class="`fixed z-40 top-9 lg:top-7 w-9 h-9 md:w-10 md:h-10 ml-6 md:ml-11 xl:ml-11 animation duration-300 ease-in-out`">
        </router-link>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8" style="padding-left: 120px;">

<!--          Prva linija-->
          <div class="w-full flex py-2">
<!--            <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-base-500 md:hidden" @click="sidebarOpen = true">-->
<!--              <span class="sr-only">Open sidebar</span>-->
<!--              <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />-->
<!--            </button>-->
            <div class="flex-1 flex justify-between">
              <div class="items-center w-full">

                <Menu id="header-items-space" as="div" class="mx-auto h-16 py-9 bg-gradient-to-r from-base-500 to-base-600 rounded-xl px-6 mt-3 lg:mt-2 z-50">

                  <div class="flex items-center justify-end h-full space-x-4 font-semibold text-white">

                    <p v-if="$store.state.auth.user" class="h-10">{{$store.state.auth.user.first_name}} {{$store.state.auth.user.last_name}}</p>
                    <MenuButton class="max-w-xs flex flex-col items-center text-sm rounded-full focus:outline-none">
                      <img v-if="$store.state.auth.user?.profile_picture" class="h-8 w-8 rounded-full border border-white" :src="$store.state.auth.user.profile_picture" alt="" />
                      <div v-if="!$store.state.auth.user?.profile_picture" class="bg-gray-300 overflow-hidden h-8 w-8 border border-white rounded-full flex items-center justify-center">
                        <font-awesome-icon icon="fa-solid fa-user" class="text-xl" />
                      </div>
                      <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-xs" />
                    </MenuButton>
                    <MenuItems class="z-10 font-normal origin-top-right absolute top-16 mt-3 w-64 rounded-md shadow-lg py-1 bg-gradient-to-r from-blur-1 via-blur-2 bg-blur ring-1 ring-black ring-opacity-5 focus:outline-none">
<!--                      <MenuItem @click="logout" v-slot="{ active }">-->
<!--                        <a href="https://hob.fra1.digitaloceanspaces.com/UPUTSTVO%20ZA%20KORIS%CC%8CC%CC%81ENJE%20PLATFORME%20U%20OBJEKTIMA.pdf" target="_blank" class="block px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-base-600 ">Tutorial</a>-->
<!--                      </MenuItem>-->
                      <MenuItem @click="logout" v-slot="{ active }">
                        <p class="block px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-base-600 ">Logout</p>
                      </MenuItem>
                    </MenuItems>
                  </div>

                </Menu>
              </div>
            </div>
          </div>

          <div class="hidden lg:flex w-10/12 bg-base-600 mx-auto justify-start items-center shadow-xl transform -translate-y-5 lg:-translate-y-8 rounded-lg">
            <div :class="`select-none rounded-l-lg flex items-center justify-start pl-3 gap-x-2 w-full rounded-lg h-11 text-sm leading-4 uppercase shadow-lg bg-base-600 text-shadow-white font-medium border-white transition ease-in-out duration-200 text-white`">
              <font-awesome-icon :icon="`fa-solid ${calculateRouteSubName.iconName}`" class="text-xl text-white" />
              <p @click="$router.push('/providers')" class="cursor-pointer">{{ calculateRouteSubName.name }}</p>
              <p v-if="selectedProviderName && showSubRoute">/&nbsp;&nbsp;{{ selectedProviderName }}</p>
            </div>
          </div>

<!--          Druga linija-->

        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8" style="padding-left: 120px">
            <!-- Replace with your content -->
<!--            <div class="py-4">-->
<!--              <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" />-->
<!--            </div>-->
            <slot/>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
  <transition
      name="custom-classes-transition"
      enter-active-class="animated tada"
      leave-active-class="animated bounceOutRight"
  >
    <loader v-if="$store.getters['ui/is_loading']"/>
  </transition>
</template>

<script>
import { ref } from 'vue'
import newLogo from "@/components/ui/NewLogo";
import sidebar from "@/components/ui/sidebar";
import loader from "@/components/ui/loader";
import errorNotification from "@/components/ui/ErrorNotification";
import successNotification from "@/components/ui/SuccessNotification";

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
// import {
//   BellIcon,
//   CalendarIcon,
//   ChartBarIcon,
//   FolderIcon,
//   HomeIcon,
//   InboxIcon,
//   MenuAlt2Icon,
//   UsersIcon,
//   XIcon,
// } from '@heroicons/vue/outline'
// import { SearchIcon } from '@heroicons/vue/solid'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

const navigation = [
  { name: 'Dashboard', href: '#', icon: 'HomeIcon', current: true },
  { name: 'Team', href: '#', icon: 'UsersIcon', current: false },
  { name: 'Projects', href: '#', icon: 'FolderIcon', current: false },
  { name: 'Calendar', href: '#', icon: 'CalendarIcon', current: false },
  { name: 'Documents', href: '#', icon: 'InboxIcon', current: false },
  { name: 'Reports', href: '#', icon: 'ChartBarIcon', current: false },
]
const userNavigation = [
  { name: 'Sign out', href: '#' },
]

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    // BellIcon,
    // MenuAlt2Icon,
    // SearchIcon,
    // XIcon,
    newLogo,
    sidebar,
    loader,
    errorNotification,
    successNotification
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      navigation,
      userNavigation,
      sidebarOpen,
    }
  },
  data() {
    return {
      open: false,
      isShowSidebar: true,
    }
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    // ...mapGetters('ui', ['selected_provider']),
    selectedProviderName() {
      return this.$store.state.benefits.selected_provider_name;
    },
    showSubRoute() {
      const routeName = this.$route.name;
      if (routeName === 'Provider' || routeName === 'ProviderAdmins') return true;
      else false;
    },
    calculateRouteSubName() {
      const routeName = this.$route.name;
      console.log(routeName, 'route name')
      switch(routeName) {
        case 'Providers':
          return {name: 'Benefit Provajderi', iconName: 'fa-store'};
        case 'Provider':
          return {name: 'Benefit Provajderi', iconName: 'fa-store'};
        case 'ProviderAdmins':
          return {name: 'Benefit Provajderi', iconName: 'fa-store'};
        case 'Home':
          return {name: 'Analitika', iconName: 'fa-chart-column'};
        case 'Companies':
          return {name: 'Klijenti', iconName: 'fa-building'};
        case 'Company':
          return {name: 'Klijenti', iconName: 'fa-building'};
        case 'Invoices':
          return {name: 'Kupovine', iconName: 'fa-arrow-right-arrow-left'};
        case 'Suggestions':
          return {name: 'Kupovine', iconName: 'fa-comment-dots'};
        case 'Jobs':
          return {name: 'Kupovine', iconName: 'fa-bars-progress'};
        default:
          return {name: 'Klijenti', iconName: 'fa-building'};
          // code block
      }
    }
  },
  methods: {
    ...mapActions('benefits', ['set_benefits_type']),
    ...mapMutations('auth', ['logOut']),
    logout() {
      this.logOut()
      this.$router.push('/login')
    },
    back_to_home() {
      this.$router.push('/')
    },
    setLogo(val) {
      this.open = val;
    },
  },
}
</script>

<style>
.container {
  width: 250px !important;
  text-align: center;
}

.progress2 {
  padding: 2px;
  border-radius: 30px;
  background: theme('colors.base.transparent_600');
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 7px;
  border-radius: 30px;
  background-image:
      linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 85%;
  background-color: theme('colors.base.500');
  animation: progressAnimation 6s;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
  /*text-shadow: 0 0 7px white, 0 0 7px white, 0 0 7px white;*/
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
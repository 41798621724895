import axios from "../../config/http";

// initial state
const state = () => ({
    cart: []
})

// getters
const getters = {
    cart(state) {
        return state.cart
    },
    cartCount(state) {
        return state.cart.length
    },
    cartHasItems(state) {
        return state.cart.length > 0
    },
}

// actions
const actions = {
    async login(context, request_data) {
        let {data} = await axios.post('user/token/', request_data)
        axios.defaults.headers.common["Authorization"] = 'Token ' + data.token
        localStorage.setItem('token', data.token)
        context.commit('setToken', data.token)
        let user = await axios.get('v2/me/')
        context.commit('setUser', user.data)
        return user
    },
    async fetchUser(context) {
        let user = await axios.get('v2/me/')
        context.commit('setUser', user.data)
        console.log("IIII")
    },
    checkAuth(context) {
        if(!context.state.token) {
            context.commit('setToken', localStorage.getItem('token'))
        }
    }
}

// mutations
const mutations = {
    cartItemToggle(state, item) {
        let new_cart = [...state.cart]
        let index = state.cart.findIndex(e=>e.pk == item.pk)
        if (index != -1) {
            new_cart.splice(index, 1)
        } else {
            new_cart.push(item)
        }

        console.log(new_cart)

        state.cart = new_cart
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <div class="pb-10">
    <div class="flex space-x-4">
      <div class="w-1/5">
        <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.category }}</label>
        <select v-model="category" id="category" name="category"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-base-500 focus:border-base-500 sm:text-sm rounded-md">
          <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
        </select>
      </div>
      <div class="w-1/5" v-if="current_category_id && selected_category.children.length">
        <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.subcategory }}</label>
        <select v-model="subcategory" id="location" name="location"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-base-500 focus:border-base-500 sm:text-sm rounded-md">
          <option v-for="subcat in selected_category.children" :value="subcat.id">{{ subcat.name }}</option>
        </select>
      </div>

      <div>
        <label for="search" class="block text-sm font-medium text-gray-700">{{ loc.search }}</label>
        <div class="mt-1 relative flex items-center">
          <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search"
                 class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"/>
          <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <search-icon class="text-gray-500 h-6 w-6"/>
          </div>
        </div>
      </div>


      <div class="flex space-x-2">
        <div>
          <label for="sort" class="block text-sm font-medium text-gray-700">{{ loc.sort_by }}</label>
          <select v-model="sorting" id="sort" name="sort"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-base-500 focus:border-base-500 sm:text-sm rounded-md">
            <option value="bought">{{ loc.bought }}</option>
            <option value="added">{{ loc.date }}</option>
            <option value="name">{{ loc.name }}</option>
            <option value="price">{{ loc.price }}</option>
          </select>
        </div>

        <div class="relative z-0 flex flex-col justify-end rounded-md">
          <button
              @click="set_ordering('+')"
              type="button"
              :class="`relative inline-flex items-center p-1 rounded-t-md border border-gray-300 ${ordering == '+' ? 'bg-base-600 text-white' : 'bg-white text-gray-500'} text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
            <ChevronUpIcon class="h-4 w-4" aria-hidden="true"/>
          </button>
          <button
              @click="set_ordering('-')"
              type="button"
              :class="`relative inline-flex items-center p-1 rounded-b-md border border-gray-300 ${ordering == '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500'} text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
            <ChevronDownIcon class="h-4 w-4" aria-hidden="true"/>
          </button>
        </div>
      </div>

    </div>

    <div class="flex space-x-4 pt-2">
      <div @click="category_providers=true" class="bg-white w-1/5 p-2 rounded-md text-gray-700 text-center hover:shadow-md border border-gray-300 cursor-pointer">{{loc.providers}}</div>
    </div>

    <modal :open="category_providers" submit-text="Load" @close="category_providers = false" @submit="fetchBenefits(); category_providers = false;" :loading="false">
      <providers-select/>
    </modal>

  </div>
</template>

<script>

// import {SearchIcon, ChevronUpIcon, ChevronDownIcon} from "@heroicons/vue/solid"
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import modal from "@/components/ui/modal";
import providersSelect from "@/components/providersSelect";


export default {
  name: "additionalFilters",
  data() {
    return {
      category_providers: false
    }
  },
  components: {
    // SearchIcon,
    // ChevronUpIcon,
    // ChevronDownIcon,
    modal,
    providersSelect
  },
  methods: {
    ...mapActions('filters', ['select_category', 'select_sort', 'set_search', 'set_ordering']),
    ...mapActions('benefits', ['fetchBenefits'])
    // ...mapMutations('filters', ['set_sort'])
  },
  computed: {
    ...mapState('filters', ['categories', 'sort', 'search', 'ordering']),
    ...mapGetters('filters', ['current_category_object', 'selected_category', 'current_category_id', "search_string"]),
    category: {
      get() {
        return this.selected_category ? this.selected_category.id : null
      },
      set(value) {
        this.select_category(value)
      }
    },
    subcategory: {
      get() {
        return this.current_category_id
      },
      set(value) {
        this.select_category(value)
      }
    },
    sorting: {
      get() {
        return this.sort
      },
      set(value) {
        this.select_sort(value)
      }
    },
    search: {
      get() {
        return this.search_string
      },
      set(value) {
        this.set_search(value)
      }
    },
  }
}
</script>

<style scoped>

</style>
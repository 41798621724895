<template>
  <ul  role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-4" v-if="benefits">
    <li v-for="benefit in benefits" class="h-64 col-span-1 bg-white rounded-lg shadow cursor-pointer hover:text-base-500 text-gray-700">

      <div class="w-full h-full flex justify-end flex-col items-start rounded-lg">
        <div class="flex items-start justify-start p-2" style="height: 35%;">
            <div class="flex items-center space-x-3">
              <p class="text-sm font-bold text-ellipsis">{{benefit.name}}</p>
            </div>
        </div>

        <div class="p-1 bg-gray-300 w-full flex" style="height: 15%;">
          <p>{{monetary(benefit.price, benefit.currency)}}</p>
          <heart-icon/>
          <shopping-cart-icon/>
        </div>

        <div class="rounded-b-lg w-full h-1/2 bg-gray-400 bg-cover" :style="`background-image: url('${getBaseImg(benefit.pictures)}');`">

        </div>
      </div>
    </li>
  </ul>
</template>

<script>
// import {ShoppingCartIcon, HeartIcon} from "@heroicons/vue/solid"

export default {
  name: "benefitList",
  props: ['benefits'],
  components: {
    // ShoppingCartIcon,
    // HeartIcon
  },
  methods: {
    getBaseImg(images) {
      if (!images || !images.length) {
        return null
      }

      function compare( a, b ) {
        if ( a.position < b.position ){
          return -1;
        }
        if ( a.position > b.position ){
          return 1;
        }
        return 0;
      }

      return images.sort( compare )[0].image;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div v-if="stats">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select @change="select_country($event, null)" id="tabs" name="tabs" class="block w-full focus:ring-base-500 focus:border-base-500 border-gray-300 rounded-md">
          <option v-for="tab in stats.countries" :key="tab.short_name" :selected="country == tab.short_name" :value="tab.short_name">{{ tab.short_name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="flex space-x-4" aria-label="Tabs">
          <span @click="select_country(null, tab.short_name)" v-for="tab in stats.countries" :key="tab.short_name" :class="[country == tab.short_name ? 'bg-base-100 text-base-700' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.short_name }}
          </span>
        </nav>
      </div>
    </div>
    <div class="pt-2" v-if="stats">
      <label for="tabs" class="sr-only">Select a company</label>
      <select @change="select_company($event, null)" id="tabs" name="tabs" class="block w-full focus:ring-base-500 focus:border-base-500 border-gray-300 rounded-md">
        <option v-for="tab in [{id: 0, name: 'Kompanija'}, ...stats.companies]" :key="tab.id" :selected="country == tab.id" :value="tab.id">{{ tab.name }}</option>
      </select>
    </div>
    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-6">Statistika</h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3" v-if="stats">
      <div class="space-y-4">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            UKUPNO DODELJENO BUDŽETA
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ monetary(stats.base_balances_sum[country].base_balance__sum, stats.base_balances_sum[country].currency) }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            UKUPNO POTROŠENO BUDŽETA
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ monetary(stats.spent[country].spent__sum, stats.spent[country].currency) }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            % ISKORIŠĆENOG BUDŽETA
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.budget_percentage[country] }} %
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            UKUPNO KUPLJENO BENEFITA
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.sum_benefits[country] }}
          </dd>
        </div>

      </div>
      <div>
        <bar-chart :chart-data="chartData"/>
      </div>
      <div class="space-y-4">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            MINIMALAN BUDŽET
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ monetary(stats.budgets[country].min, stats.spent[country].currency) }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            MAKSIMALAN BUDŽET
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ monetary(stats.budgets[country].max, stats.spent[country].currency) }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            PROSEČAN BUDŽET PO ZAPOSLENOM
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ monetary(stats.budgets[country].average, stats.spent[country].currency) }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            UKUPNO ZAPOSLENIH U PROGRAMU
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.employees_count[country] }}
          </dd>
        </div>

        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt class="text-sm font-medium text-gray-500 truncate">
            % ZAPOSLENIH KOJI SU KUPILI MAKAR JEDAN BENEFIT
          </dt>
          <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ stats.percentage_employees_one_plus_benefit[country] }}%
          </dd>
        </div>
      </div>
    </dl>
  </div>
</template>

<script>
// import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import {mapActions, mapState} from "vuex";
import benefitList from "@/components/benefitList";
import AdditionalFilters from "@/components/additionalFilters";
import BarChart from "@/components/charts/BarChart";

export default {
  data() {
    return {
      stats: null,
      country: null,
      currency: null,
      company: null
    }
  },

  watch: {
    country: function (value) {
      let countries = []

      this.stats.countries.forEach(e=>{
        countries.push({short_name: e.short_name, curr: e.currency})
      })

      let country = countries.find(e=>e.short_name=value)
      this.currency = country.curr
    }
  },

  components: {
    AdditionalFilters,
    // MailIcon,
    // PhoneIcon,
    benefitList,
    BarChart
  },
  methods: {
    ...mapActions('benefits', ['fetchBenefits', 'fetchHome', 'load_mode']),
    select_country(e, code) {
      if(code) {
        this.country = code
      }

      if (e) {
        this.country = e.target.value
      }
    },
    async select_company(e, code) {
      if(code) {
        this.company = code
      }

      if (e) {
        this.company = e.target.value
      }

      let {data} = await this.axios.get(`v2/admin/budgets/?company=${this.company}`)
      this.stats = data
    }
  },
  async mounted() {
    try {
      let {data} = await this.axios.get('v2/admin/budgets/')
      this.stats = data;
      this.country = [...data.countries][0].short_name
    } catch (e) {
      console.log('err no data!')
    }
    // this.fetchHome()
    // const _this = this
    // window.onscroll = () => {
    //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 10;
    //   if (bottomOfWindow) {
    //     _this.load_mode()
    //   }
    // };
  },
  computed: {
    ...mapState('benefits', ['benefits_type']),
    chartData() {
      return {
        labels: this.stats ? [...this.stats.spent_per_category[this.country].categories] : [],
        datasets: [
          {
            label: "Potroseno po kategoriji",
            backgroundColor: '#f87979',
            data: this.stats ? [...this.stats.spent_per_category[this.country].values] : []
          }
        ]
      }
    }
  }
}
</script>